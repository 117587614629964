















































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '../../types';

export default Vue.extend({
  props: {
    pageId: {
      type: String,
      required: false,
      default: null,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as Error|null,
      loading: false,
      form: {
        title: '',
        slug: '',
        weight: 1,
        type: null as 'markdown'|'link'|null,
        is_public: false,
        content: '',
      },
      noCache: Date.now(),
      iframeHeight: 300,
      keep: {
        link: '',
        markdown: '',
      },
      validation: {
        title: null,
        slug: null,
        weight: null,
        type: null,
        is_public: null,
        content: null,
      } as {[key: string]: string|null},
    };
  },
  computed: {
    ...mapGetters({
      find: 'business/Pages/Find',
      findBusiness: 'business/Detect',
    }),
    business(): ApiResource.Business {
      return this.findBusiness(this.businessId);
    },
    page(): ApiResource.Profile.Page | undefined {
      return this.find(this.business.id, this.pageId);
    },
    renderMarkdownUrl(): string | undefined {
      if (!this.page) return undefined;
      return `${process.env.VUE_APP_API_URL}/web-api/RenderProfileMarkdownPageSandbox.html?id=${this.page.id}&no-cache=${this.noCache}`;
    },
    markdownIframe(): HTMLIFrameElement | undefined {
      if (this.page?.type === 'markdown') return (this.$refs.iframe as HTMLIFrameElement);
      return undefined;
    },
  },
  watch: {
    page: {
      immediate: true,
      handler(to): void {
        if (to) {
          this.form.title = to.title;
          this.form.slug = to.slug;
          this.form.weight = to.weight;
          this.form.type = to.type;
          this.form.is_public = to.is_public;
          this.form.content = to.content;
        }
      },
    },
    'form.type': {
      immediate: true,
      handler(to: 'markdown'|'link', from: 'markdown'|'link'): void {
        if (to && from && to !== from) {
          this.keep[from] = this.form.content;
          this.form.content = this.keep[to];
        }
      },
    },
  },
  created() {
    window.addEventListener('message', this.onMessageHandler);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessageHandler);
  },
  methods: {
    onMessageHandler(ev: MessageEvent): void {
      if (this.form.type !== 'markdown' || !ev.isTrusted || ev.origin !== process.env.VUE_APP_API_URL) return;
      if (ev.data.href !== this.renderMarkdownUrl) return;
      if (this.markdownIframe && ev.data.height) {
        // this.markdownIframe.style.height = `${parseInt(ev.data.height, 10)}px`;
        this.iframeHeight = parseInt(ev.data.height, 10);
      }
    },
    async submit(): Promise<void> {
      if (this.validate()) return;
      const action = this.pageId ? 'business/Pages/Update' : 'business/Pages/Create';
      const emit = this.pageId ? 'page-updated' : 'page-created';

      this.loading = true;

      const data = await this.$store.dispatch(action, {
        BUSINESS_ID: this.businessId,
        PAGE_ID: this.pageId || null,
        form: this.form,
      }).catch((e) => { this.error = e; });

      this.loading = false;
      if (!data) return;

      if (this.markdownIframe) this.noCache = Date.now();

      this.$emit(emit, data.data);
    },
    validate(validationErrors: {[key: string]: string} = {}): boolean {
      const { form: f } = this;
      const v = { ...validationErrors };

      if (!f.title) v.title = 'notOptional';
      else if (f.title.length < 3) v.title = 'length';
      else if (f.title.length > 36) v.title = 'length';

      if (!f.slug) v.slug = 'notOptional';
      else if (f.slug.length < 3) v.slug = 'length';
      else if (f.slug.length > 36) v.slug = 'length';
      else if (f.slug.includes('--')) v.slug = 'slug';
      else if (!f.slug.match(/^[0-9a-z\\-]+$/)) v.slug = 'slug';
      else if (f.slug.match(/^-|-$/)) v.slug = 'slug';

      if (!f.weight) v.weight = 'notOptional';
      else if (f.weight > 1000) v.weight = 'max';
      else if (f.weight < 0) v.weight = 'min';

      if (f.is_public !== true && f.is_public !== false) v.is_public = 'boolVal';

      if (!f.type) v.type = 'notOptional';
      else if (!['markdown', 'link'].includes(f.type)) v.type = 'in';

      if (f.type === 'link') {
        if (!f.content) v.content = 'notOptional';
        if (f.content.length > 255) v.content = 'length';

        const url = new URL(f.content);

        if (url.protocol !== 'https:') v.content = 'httpsUrl';
        // TODO else if (!WHITELIST.includes(url.host)) v.content = 'whitelistedHostname';
      } else if (f.type === 'markdown') {
        if (f.content.length > 20000) v.content = 'length';
      }

      this.validation = v;

      return !Object.keys(v).every((k) => v[k] === null);
    },
  },
});
