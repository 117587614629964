















import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import PageForm from '@/components/dashboard/PageForm.vue';

export default Vue.extend({
  components: {
    PageForm,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
    pageId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/Pages/Find',
    }),
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    page(): ApiResource.Profile.Page[] {
      return this.find(this.business.id, this.pageId);
    },
  },
  watch: {
    pageId: {
      immediate: true,
      handler(to) {
        if (to) {
          this.$store.dispatch('business/Pages/Find', { BUSINESS_ID: this.business.id, PAGE_ID: this.pageId })
            .catch(() => { this.$router.push({ name: 'dashboard.pages' }); });
        }
      },
    },
  },
  methods: {
    createdPage(page: ApiResource.Profile.Page) {
      this.$router.push({ name: 'dashboard.page', params: { vanity: this.businessVanity, pageId: page.id } });
    },
    updatedPage() {
      // this.$router.push({ name: 'dashboard.pages', params: { vanity: this.businessVanity } });
    },
  },
});
